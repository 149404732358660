<template>
  <div class="flex flex-row items-start pb-4 px-3 pt-4 gap-1 select-none">
    <div
      :class="{ activeClass: currentIndex == item.index }"
      v-for="(item, index) in tabs"
      :key="item.label"
      @click="handleClick(item.index)"
      class="flex flex-col-reverse md:flex-row tab items-center md:justify-between w-full md:w-auto md:mr-4"
    >
      <span class="text-xs md:text-xs lg:text-sm text-center md:mr-2">{{ item.label }}</span>
      <div class="count h-7 w-7 flex justify-center items-center">
        <div class="font-bold text-xs md:text-xs lg:text-sm">{{ item.count }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["tabs", "currentIndex"],
  data() {
    return {
      currentIndexSeleted:this.currentIndex,
    };
  },
  methods: {
    handleClick(index) {
      this.$emit("updateIndex", index);
    },
    handleChange(option){
     this.currentIndexSeleted=option;
      this.$emit("updateIndex", option);
    }
  },
};
</script>
<style lang="scss" scoped>
.activeClass {
  color: $primaryColor;
}
.tab {
  cursor: pointer;
  span {
    line-height: 18px;
    color: #49454F;
    font-weight: 500;
  }
  .count {
    line-height: 28px;
    text-align: center;
    border-radius: 10px;
    color: #ffffff;
    background: #49454F;
  }

  .count-word {
    width: 110px;
  }
}
.activeClass {
  span {
    color: $primaryColor;
  }
  .count {
    background: $primaryColor;
    color: white;
  }
}
</style>
