<template>
  <div class="top">
    <el-row>
      <el-col
        v-if="(userInfo.userType == 2 || userInfo.role == 5) && userInfo.role != 4"
        :span="24"
        class="Cbnbtn p-1 desktop:p-0 laptop:p-1"
      >
        <el-button plain @click="TankBtn" size="small" class="pb-2">{{
          $t("btn.Cbn") 
        }}</el-button>
      </el-col>
      <!-- <div>{{ count }}</div> -->
      <el-col :span="24">
        <tabs
          :tabs="tabsList"
          @updateIndex="updateIndex"
          :currentIndex="upIndex"
        ></tabs>
        <!-- <br v-if="isMobileScreen"/> -->
        <div style="padding:20px 0px; background-color: white; border-radius: 10px;">
          <created
            ref="barging" 
            :orderList="orderList"
            :loading="createdloading"
            @updateList="updateList"
            @refreshOnClick="refresh"
            :orderIndex="upIndex"
            :isShowTopinpBar="true"
            :pilotTabs="pilotTabs"
            @focused="handleStopRefresh"
            @blurred="handleStartRefresh"
          />
          <div class="flex justify-end">
            <pagination
              v-show="total > 0"
              style="background: #f2f7fa"
              :total="total"
              :page.sync="pageParams.pageNum"
              :limit.sync="pageParams.pageSize"
              @pagination="handlePagechange"
            ></pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import axios from "axios";
import { getClientList } from "@/services/user";
import Created from "@/view/certificate/BargingNomination/Created.vue";
import { mapState } from "vuex";
import { checkIsNotEmptyOrNull } from '@/utils/tools';

export default {
  name: "quantity",
  components: {
    tabs,
    Created,
  },
  data() {
    return {
      tabsList: [
        { label: "Created", count: 0, index: "0" },
        { label: "Barge Ready", count: 0, index: "1" },
        { label: "Jetty Assigned", count: 0, index: "2" },
        { label: "Booked", count: 0, index: "2.0" },
        { label: "Loading Started", count: 0, index: "6" },
        { label: "Loading Completed", count: 0, index: "7" },
        { label: "Signing in Progress", count: 0, index: "3" },
        { label: "Completed", count: 0, index: "4" },
        { label: "Cancelled", count: 0, index: "5" },
      ],
      pilotTabs: ["2.0", "6", "7", "3", "4"],
      upIndex: 0,
      Cbnbtn: false,
      userType: 0,
      createdtable: {},
      BargeTable: {},
      tansvisible: false,
      bargingvisible: false,
      // tansnum: {},
      orderList: [],
      total: 0,
      pageParams: {
        pageNum: 1,
        pageSize: 50,
      },
      createdloading: true,
      refreshInterval: null,
      source: null,
      count: 0,
      countInterval: null
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  // activated(){
  //   this.getUser();
  //   let orderStatus = sessionStorage.getItem("orderStatus");
  //   if (orderStatus) {
  //     this.upIndex = +orderStatus;
  //   }
  //   this.getList();
  //   console.log("activated")
  //   this.handleStartRefresh();
  // },
  created(){
    this.getUser();
    let orderStatus = sessionStorage.getItem("orderStatus");
    if (orderStatus) {
      this.upIndex = +orderStatus;
    }
    this.upIndex = window.localStorage.getItem('upIndex')||0;

    // for terminal pilot role
    if( this.$store.state.userInfo.userType == '1' && this.$store.state.userInfo.role == '10' ) {
      this.tabsList = this.tabsList.filter( item => this.pilotTabs.includes(item.index))
      if(! this.pilotTabs.includes( this.upIndex ) ) {
        this.upIndex = '2'
      }
    }

    this.handleStartRefresh();
    this.getList();
  },
  methods: {
    handleStartRefresh() {
      this.handleStopRefresh();

      this.countInterval = setInterval(() => {
        this.count += 1;
      }, 1000);

      this.refreshInterval = setInterval(() => {
        this.count = 0;
        this.refresh();
      }, 10000);
    },
    handleStopRefresh() {
      this.count = 0;

      if (this.source) {
        this.source.cancel();
        this.source = null;
      }

      if (this.countInterval) {
        clearInterval(this.countInterval);
      }

      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    },
    refresh() {
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      if (this.$refs.barging) {
        this.createdloading = true;
        let searchValue = this.$refs.barging.Tankinput,searchDateType=this.$refs.barging.Selectvalue;
        this.getList(searchValue,searchDateType, this.source.token);
      }
    },
    getUser() {
      this.userType = this.userInfo.userType;
      // 船舶进来的判断
      if (this.userType == 4) {
        this.tansvisible = true;
      }
      if (this.userType == 1) {
        // 码头进来的判断
        this.tansvisible = true;
        this.upIndex = 1;
      }
      if (this.userType == 2) {
        // 客户进来的判断
        this.tansvisible = true;
        this.upIndex = 0;
        this.Cbnbtn = true;
      }
    },
    handlePagechange(){
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      let searchValue = this.$refs.barging.Tankinput,searchDateType=this.$refs.barging.Selectvalue;
      this.getList(searchValue,searchDateType,this.source.token);
    },
    changeCompanyNametoUpperCase(){
      if (this.orderList!=null && this.orderList.length>0) {
        this.orderList.forEach(e=> e.customerCompanyName = checkIsNotEmptyOrNull( e['customerCompanyName'])?e['customerCompanyName'].trim().toUpperCase():'' )
      }
    },
    getList(searchValue, searchDateType, cancelToken) {
      let jettyAssignedCall = this.jettyAssignedCall(this.upIndex)
      let statusString = jettyAssignedCall ? '2.0' : undefined
      getClientList(
        jettyAssignedCall ? 2 : this.upIndex,
        this.pageParams,
        searchValue,
        searchDateType,
        cancelToken, 
        statusString
      ).then((res) => {
        if (res.code == 200) {
          this.orderList = res.data.table.rows;
          this.changeCompanyNametoUpperCase();
          this.total = res.data.table.total;
          this.tabsList.forEach((i) => {
            i.count = res.data[i.index];
          });
        }
      }).finally(() => {
        setTimeout(() => {
          this.createdloading = false;
        }, 2000);
      });
    },
    updateList(searchValue, searchDateType) {
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      this.getList(
        searchValue ? searchValue : null,
        searchDateType ? searchDateType : null,
        this.source.token
      );
    },
    updateIndex(index) {
      if (this.source) {
        this.source.cancel();
      }
      const CancelToken = axios.CancelToken;
      this.source = CancelToken.source();

      this.upIndex = index;
      this.pageParams.pageNum = 1
      let jettyAssignedCall = this.jettyAssignedCall(this.upIndex)
      let statusString = jettyAssignedCall ? '2.0' : undefined
      getClientList( jettyAssignedCall ? 2 : index, this.pageParams, null, null, this.source.token, statusString).then((res) => {
        this.orderList = res.data.table.rows;
       this.changeCompanyNametoUpperCase();
        this.total = res.data.table.total;
      });
      window.localStorage.setItem('upIndex',index)
      this.$emit("upindex", index);
    },
    TankBtn() {
      this.$router.push({
        path: "/barging/index",
        query: {
          pageStatus: 1,
        },
      });
      this.$emit("upindex", this.upIndex);
      sessionStorage.setItem("pageStatus", 1);
      sessionStorage.setItem("editData", JSON.stringify({}));
    },
    // 弹窗跳转
    upup(val) {
      if (val == 4) {
        this.bargingvisible = false;
        this.tansvisible = true;
      }
    },
    jettyAssignedCall() {
      return this.upIndex == '2.0'
    }
  },
};
</script>

<style scoped>
.top {
  /*padding: 30px 0px 10px 0px; */
}
.el-button--default {
  color: #fff;
  background-color: #008CDB;
}
.el-button--default.is-plain:hover {
  color: #fff;
  background: #008CDB;
  border-color: #008CDB;
}
.el-button--default:focus {
  color: #fff !important;
  background: #008CDB !important;
  border-color: #008CDB;
}

.Cbnbtn {
  text-align: right;
  /* margin-top: 38px; */
  /* padding-right: 36px; */
  /* padding-right: 2rem; */
}
</style>
